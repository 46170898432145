import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  Link,
  Button,
  useDisclosure,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  useColorMode,
  IconButton
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { useLanguage, useTranslations } from '../LanguageContext'; // Import useLanguage and useTranslations hooks

type HeaderProps = {
  setIsAdmin: (isAdmin: boolean) => void;
};

const Header: React.FC<HeaderProps> = ({ setIsAdmin }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { colorMode, toggleColorMode } = useColorMode();
  const { language, toggleLanguage } = useLanguage(); // Use language and toggleLanguage from context
  const translations = useTranslations(); // Use translations from context
  const location = useLocation(); // Use useLocation to get the current path

  useEffect(() => {
    // Check if token exists on page load
    const token = localStorage.getItem('adminToken');
    if (token) {
      setIsAdmin(true);
    }
  }, [setIsAdmin]);

  const handleLogin = () => {
    if (password === 'admin') { // Replace with your desired admin password validation logic
      const token = 'eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTcyMTg0NTA3NSwiaWF0IjoxNzIxODQ1MDc1fQ.MLKtxZNQb4tkkh0d4v8OJzqQ2VClSZel7M1-bBNWVP8';
      localStorage.setItem('adminToken', token);
      setIsAdmin(true);
      onClose();
      setError('');
    } else {
      setError(translations.incorrectPassword);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsAdmin(false);
  };

  return (
    <>
    <Box bg={colorMode === 'dark' ? 'gray.800' : 'gray.100'} px={4} py={2}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Link
            as={RouterLink}
            to="/"
            px={2}
            py={1}
            rounded="md"
            _hover={{ textDecoration: 'none', bg: colorMode === 'dark' ? 'gray.700' : 'gray.200' }}
            _activeLink={{
              bg: colorMode === 'dark' ? 'gray.700' : 'gray.800',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            {translations.home}
          </Link>
          <Box textAlign="center">
          <Link
            as={RouterLink}
            to="/review"
            px={2}
            py={1}
            rounded="md"
            _hover={{ textDecoration: 'none', bg: colorMode === 'dark' ? 'gray.700' : 'gray.200' }}
            _activeLink={{
              bg: colorMode === 'dark' ? 'gray.700' : 'gray.800',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            {translations.submitReviewHeader}
          </Link>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <IconButton
            aria-label="Toggle color mode"
            icon={colorMode === 'dark' ? <SunIcon /> : <MoonIcon />}
            onClick={toggleColorMode}
            mr={4}
          />
          <Button onClick={toggleLanguage} mr={4}>
            {language === 'en' ? 'DE' : 'EN'}
          </Button>
          {location.pathname === '/admin/aymanfarhat/j250nwf1c4z6W73M' && localStorage.getItem('adminToken') ? (
            <Button onClick={handleLogout} ml={4}>{translations.logout}</Button>
          ) : (
            location.pathname === '/admin/aymanfarhat/j250nwf1c4z6W73M' && (
              <Button onClick={onOpen} ml={4}>{translations.adminLogin}</Button>
            )
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translations.adminLogin}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              type="password"
              placeholder={translations.enterPassword}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && <Text color="red.500" mt={2}>{error}</Text>}
          </ModalBody>
          <ModalFooter>
            <Button style={{ backgroundColor: '#32CD32', color: 'white' }} mr={3} onClick={handleLogin}>
              {translations.login}
            </Button>
            <Button variant="ghost" onClick={onClose}>{translations.cancel}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
    <Box textAlign="center">
    <Flex align="center" justify="center">
        <Text fontSize={{ base: "3xl", md: "6xl" }} color="#ffb318" fontWeight={1000}>
          AF AUTOMOBILE
        </Text>
      </Flex>

    </Box>
    </>
  );
};

export default Header;
