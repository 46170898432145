import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import {
  Box, SimpleGrid, Card, CardHeader, CardBody, CardFooter, Text, Flex, Icon, useToast, Button, useMediaQuery, VStack, HStack
} from '@chakra-ui/react';
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa';
import { useLanguage, useTranslations } from '../LanguageContext';
import { Link as RouterLink } from 'react-router-dom';
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig';
import ImageCarousel from '../components/ImageCarousel';

interface Review {
  _id: string;
  Name: string;
  carName: string;
  review: string;
  rating: number;
  ownerComment?: string;
  createdAt: Date;
  approved: boolean;
}

interface ImageData {
  url: string;
  approved: boolean;
}

const ReviewCards: React.FC = () => {
  const { language } = useLanguage();
  const translations = useTranslations();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [visibleReviews, setVisibleReviews] = useState<Review[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const observerRef = useRef<HTMLDivElement | null>(null);
  const toast = useToast();

  const reviewsPerPage = 8; // Number of reviews per page for web
  const reviewsPerLoad = 3; // Number of reviews to load at a time on mobile

  useEffect(() => {
    fetchReviews();
    fetchImages();
  }, []);

  useEffect(() => {
    if (isMobile && observerRef.current) {
      const observer = new IntersectionObserver(handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Trigger when 50% of the item is visible
      });

      if (observerRef.current) {
        observer.observe(observerRef.current);
      }

      return () => {
        if (observerRef.current) {
          observer.unobserve(observerRef.current);
        }
      };
    }
  }, [isMobile, visibleReviews]);

  const fetchReviews = async () => {
    try {
      const response = await axios.get<Review[]>('https://backend.afmobile.de/api/reviews');
      const sortedReviews = response.data
        .filter(review => review.approved)  // Only keep approved reviews
        .sort((a: Review, b: Review) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      setReviews(sortedReviews);
      setVisibleReviews(sortedReviews.slice(0, reviewsPerLoad)); // Load initial reviews for mobile
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };
  
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const fetchImages = async () => {
    try {
      const { data: approvedImages }: { data: ImageData[] } = await axios.get('https://backend.afmobile.de/api/images/approved');
      const approvedImageUrls = approvedImages.map((image: ImageData) => image.url);
      const imageRefs = ref(storage, 'uploads');
      const imageList = await listAll(imageRefs);
      const imageUrls = await Promise.all(
        imageList.items.map(async item => {
          try {
            const url = await getDownloadURL(item);
            return url;
          } catch (err) {
            console.error('Error fetching download URL:', err);
            return null;
          }
        })
      );
      const validImageUrls = imageUrls.filter(url => url !== null) as string[];
      const filteredImageUrls = validImageUrls.filter(url => approvedImageUrls.includes(url));
      setImages(filteredImageUrls);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const renderStars = (rating: number) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <Flex>
        {[...Array(fullStars)].map((_, index) => (
          <Icon as={FaStar} key={index} color="yellow.400" />
        ))}
        {halfStar && <Icon as={FaStarHalfAlt} color="yellow.400" />}
        {[...Array(emptyStars)].map((_, index) => (
          <Icon as={FaRegStar} key={index} color="yellow.400" />
        ))}
      </Flex>
    );
  };

  const calculateAverageRating = () => {
    const approvedReviews = reviews.filter(review => review.approved);
    if (approvedReviews.length === 0) return 0;
    const totalRating = approvedReviews.reduce((acc, review) => acc + review.rating, 0);
    return totalRating / approvedReviews.length;
  };

  const averageRating = calculateAverageRating();

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting && visibleReviews.length < reviews.length) {
        setTimeout(() => {
          const nextPage = currentPage + 1;
          const nextReviews = reviews.slice(0, (nextPage + 1) * reviewsPerLoad);
          setVisibleReviews(nextReviews.filter(review => review.approved)); // Ensure only approved reviews are visible
          setCurrentPage(nextPage);
        }, 100); // Simulate network delay
      }
    },
    [currentPage, reviews, visibleReviews]
  );
  

  const approvedReviews = reviews.filter(review => review.approved);

  // Pagination logic for web
  const currentReviews = approvedReviews.slice(
    currentPage * reviewsPerPage,
    (currentPage + 1) * reviewsPerPage
  );

  return (
    <Box p={4} height={isMobile ? 'auto' : '100%'} overflowY={isMobile ? 'auto' : 'visible'}>
      <VStack spacing={4} mb={8}>
        <ImageCarousel images={images} />
        <Box textAlign="center">
          <Text fontSize="2xl" fontWeight="bold">
            {translations.averageRating}: {averageRating.toFixed(1)}
          </Text>
        </Box>
        {renderStars(averageRating)}
        <Button as={RouterLink} to="/review" colorScheme="teal" variant="solid">
          {translations.submitReviewButton}
        </Button>
      </VStack>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {isMobile ? (
          visibleReviews.map((review, index) => (
            <Card key={review._id} minH="300px" width="100%" ref={index === visibleReviews.length - 2 ? observerRef : null}>
              <CardHeader>
                <Flex justifyContent="space-between">
                  <Box textAlign="left">
                    <Text fontWeight="bold">{translations.name}: {review.Name}</Text>
                    <Text>{translations.carName}: {review.carName}</Text>
                  </Box>
                </Flex>
              </CardHeader>
              <CardBody textAlign="left">
                <Text mb={2}>{translations.review}: {review.review}</Text>
                {renderStars(review.rating)}
              </CardBody>
              {review.ownerComment && (
                <CardFooter textAlign="left">
                  <Text>{translations.ownerComment}: {review.ownerComment}</Text>
                </CardFooter>
              )}
            </Card>
          ))
        ) : (
          currentReviews.map((review) => (
            <Card key={review._id} minH="300px">
              <CardHeader>
                <Flex justifyContent="space-between">
                  <Box textAlign="left">
                    <Text fontWeight="bold">{translations.name}: {review.Name}</Text>
                    <Text>{translations.carName}: {review.carName}</Text>
                  </Box>
                </Flex>
              </CardHeader>
              <CardBody textAlign="left">
                <Text mb={2}>{translations.review}: {review.review}</Text>
                {renderStars(review.rating)}
              </CardBody>
              {review.ownerComment && (
                <CardFooter textAlign="left">
                  <Text>{translations.ownerComment}: {review.ownerComment}</Text>
                </CardFooter>
              )}
            </Card>
          ))
        )}
      </SimpleGrid>

      {/* Pagination controls for web */}
      {!isMobile && (
  <Flex justifyContent="center" mt={4}>
    <HStack spacing={4}>
      <Button
        onClick={() => handlePageChange(currentPage - 1)}
        isDisabled={currentPage === 0}
      >
        {translations.previous}
      </Button>
      <Button
        onClick={() => handlePageChange(currentPage + 1)}
        isDisabled={(currentPage + 1) * reviewsPerPage >= approvedReviews.length}
      >
        {translations.next}
      </Button>
    </HStack>
  </Flex>
)}
    </Box>
  );
};

export default ReviewCards;
