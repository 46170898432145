import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Button, FormControl, FormLabel, Input, Textarea,
  HStack, Icon, Alert, AlertIcon, VStack, useMediaQuery, FormErrorMessage,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Image
} from '@chakra-ui/react';
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useTranslations } from '../LanguageContext'; // Import useTranslations hook

const Home: React.FC = () => {
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState<number>(0);
  const [formData, setFormData] = useState({
    Name: '',
    carName: '',
    review: '',
  });
  const [errors, setErrors] = useState({
    Name: '',
    carName: '',
    review: '',
    rating: '',
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const [isModalOpen, setIsModalOpen] = useState(true); // State to manage modal visibility
  const translations = useTranslations(); // Use translations from context
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
        setErrorMessage(null);
        if (successMessage) {
          navigate('/'); 
        }
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage, navigate]); // Include navigate in dependency array

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {
      Name: '',
      carName: '',
      review: '',
      rating: '',
    };

    if (!formData.Name) {
      newErrors.Name = translations.nameError;
    }
    if (!formData.carName) {
      newErrors.carName = translations.carNameError;
    }
    if (!formData.review) {
      newErrors.review = translations.reviewError;
    }
    if (rating === 0) {
      newErrors.rating = translations.ratingError;
    }

    setErrors(newErrors);
    return Object.values(newErrors).every(error => error === '');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
  
    const { Name, carName, review } = formData;
    const data = {
      Name,
      carName,
      review,
      rating,
      approved: false, // Set approved to false by default
    };
  
    console.log('Form Data:', data);
  
    try {
      const response = await axios.post('https://backend.afmobile.de/api/reviews', data);
      console.log('Review submitted:', response.data);
      setSuccessMessage(translations.successMessage);
      setErrorMessage(null);
    } catch (error) {
      console.error('Error submitting review:', error);
      setErrorMessage(translations.errorMessage);
      setSuccessMessage(null);
    }
  };
  

  return (
    <>
      <Box maxW={isLargerThan800 ? "3xl" : "md"} mx="auto" mt={10} p={6} borderWidth={1} borderRadius="lg" as="form" onSubmit={handleSubmit}>
        <VStack spacing={4}>
          {successMessage && (
            <Alert status='success'>
              <AlertIcon />
              {successMessage}
            </Alert>
          )}
          {errorMessage && (
            <Alert status='error'>
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          
          <FormControl id="name" mb={4} isInvalid={!!errors.Name} isRequired>
            <FormLabel>{translations.name}</FormLabel>
            <Input
              name="Name"
              placeholder={translations.name}
              onChange={handleInputChange}
            />
            <FormErrorMessage>{errors.Name}</FormErrorMessage>
          </FormControl>

          <FormControl id="car-name" mb={4} isInvalid={!!errors.carName} isRequired>
            <FormLabel>{translations.carName}</FormLabel>
            <Input
              name="carName"
              placeholder={translations.carName}
              onChange={handleInputChange}
            />
            <FormErrorMessage>{errors.carName}</FormErrorMessage>
          </FormControl>

          <FormControl id="review" mb={4} isInvalid={!!errors.review} isRequired>
            <FormLabel>{translations.review}</FormLabel>
            <Textarea
              name="review"
              placeholder={translations.review}
              onChange={handleInputChange}
            />
            <FormErrorMessage>{errors.review}</FormErrorMessage>
          </FormControl>

          <FormControl id="rating" mb={4} isInvalid={!!errors.rating} isRequired>
            <FormLabel>{translations.rating}</FormLabel>
            <HStack spacing={1}>
              {Array.from({ length: 5 }, (_, index) => (
                <Box
                  as="button"
                  key={index}
                  type="button"
                  onClick={() => setRating(index + 1)}
                  onMouseEnter={() => setHover(index + 1)}
                  onMouseLeave={() => setHover(rating)}
                >
                  <Icon
                    as={FaStar}
                    color={(hover || rating) > index ? 'teal.500' : 'gray.300'}
                    w={10}
                    h={10}
                  />
                </Box>
              ))}
            </HStack>
            <FormErrorMessage>{errors.rating}</FormErrorMessage>
          </FormControl>

          <Button colorScheme="teal" type="submit" size={isLargerThan800 ? "lg" : "md"}>{translations.submitReview}</Button>
        </VStack>
      </Box>
    </>
  );
};

export default Home;
