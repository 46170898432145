import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Drawer, DrawerBody,
  DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Textarea, FormControl,
  FormLabel, Input, useDisclosure, useToast, Flex, InputGroup, InputRightElement,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent,
  AlertDialogOverlay, AlertDialogCloseButton, Text, Select, Checkbox, HStack, Icon
} from '@chakra-ui/react';
import { ViewIcon, DeleteIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FaStar, FaRegStar, FaStarHalfAlt, FaCheck } from 'react-icons/fa';
import { ButtonGroup } from '@chakra-ui/react';
import { useLanguage, useTranslations } from '../LanguageContext';
import UploadComponent from '../components/UploadComponent';  // Import the new UploadComponent



interface Review {
  _id: string;
  Name: string;
  carName: string;
  review: string;
  rating: number;
  ownerComment?: string;
  createdAt: Date;
  approved: boolean;
}

interface DashboardProps {
  isAdmin: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ isAdmin }) => {
  const { language } = useLanguage();
  const translations = useTranslations();
  const [reviews, setReviews] = useState<Review[]>([]);
  const [selectedReview, setSelectedReview] = useState<Review | null>(null);
  const [comment, setComment] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [reviewToDelete, setReviewToDelete] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const toast = useToast();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const reviewsPerPage = 10;
  const pagesVisited = currentPage * reviewsPerPage;
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedCar, setSelectedCar] = useState<string>('');
  const [ratingFilter, setRatingFilter] = useState<number | null>(null);
  const [ownerCommented, setOwnerCommented] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [approvedFilter, setApprovedFilter] = useState<string>('all'); // New state for approved filter

  const handlePageChange = (newPage: number) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  useEffect(() => {
    if (selectedReview) {
      setComment(selectedReview.ownerComment || '');
    }
  }, [selectedReview]);

  const handleSaveComment = async () => {
    if (!selectedReview) return;

    try {
      const response = await axios.put(`https://backend.afmobile.de/api/reviews/${selectedReview._id}`, {
        ownerComment: comment,
      });

      if (response.status === 200) {
        await fetchReviews();
        onClose();
        toast({
          title: translations.commentUpdated,
          description: translations.commentUpdatedDesc,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to update');
      }
    } catch (error) {
      toast({
        title: translations.commentUpdateError,
        description: translations.commentUpdateErrorDesc,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchReviews = async () => {
    try {
      const response = await axios.get<Review[]>('https://backend.afmobile.de/api/reviews');
      const sortedReviews = response.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      setReviews(sortedReviews);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const clearFilters = () => {
    setSearchTerm('');
    setStartDate('');
    setEndDate('');
    setSelectedCar('');
    setRatingFilter(null);
    setOwnerCommented(false);
    setApprovedFilter('all'); // Reset approved filter
    setCurrentPage(0);
  };

  const calculateAverageRating = (reviews: Review[]) => {
    if (reviews.length === 0) {
      return 0;
    }
    
    const totalRating = reviews.reduce((acc, review) => acc + review.rating, 0);
    return totalRating / reviews.length;
  };

  const handleView = (review: Review) => {
    setSelectedReview(review);
    setComment(review.ownerComment || '');
    onOpen();
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const handleCarChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCar(e.target.value);
  };

  const handleRatingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRatingFilter(Number(e.target.value));
  };

  const handleOwnerCommentChange = (value: string) => {
    if (value === 'all') {
      setOwnerCommented(false);
    } else {
      setOwnerCommented(value === 'yes');
    }
  };
  

  const handleApprovedFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setApprovedFilter(e.target.value);
  };

  const renderAverageRating = (reviews: Review[]) => {
    const averageRating = calculateAverageRating(filteredReviews);
    
    const fullStars = Math.floor(averageRating);
    const halfStar = averageRating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
    
    return (
      <HStack spacing={1} alignItems="center">
        <Text fontSize="xl" fontWeight="bold"             color="#ffb318"
        >
          {translations.averageRating}: 
        </Text>
        {[...Array(fullStars)].map((_, index) => (
          <Icon
            as={FaStar}
            key={`full-${index}`}
            color="#ffb318"
            w={5}
            h={5}
          />
        ))}
        {halfStar && (
          <Icon
            as={FaStarHalfAlt}
            key="half"
            color="#ffb318"
            w={5}
            h={5}
          />
        )}
        {[...Array(emptyStars)].map((_, index) => (
          <Icon
            as={FaRegStar}
            key={`empty-${index}`}
            color="#ffb318"
            w={5}
            h={5}
          />
        ))}
        <Text fontSize="xl" fontWeight="bold"             color="#ffb318"        >
          {averageRating.toFixed(1)}
        </Text>
      </HStack>
    );
  };

  const handleDelete = async () => {
    if (reviewToDelete) {
      try {
        await axios.delete(`https://backend.afmobile.de/api/reviews/${reviewToDelete}`);
        setReviews(reviews.filter((review) => review._id !== reviewToDelete));
        onDeleteClose();
        toast({
          title: translations.reviewDeleted,
          description: translations.reviewDeletedDesc,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: translations.reviewDeleteError,
          description: translations.reviewDeleteErrorDesc,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleApprove = async (reviewId: string, approved: boolean) => {
    try {
      const response = await axios.patch(`https://backend.afmobile.de/api/reviews/${reviewId}/approve`, {
        approved,
      });

      if (response.status === 200) {
        await fetchReviews();
        toast({
          title: approved ? translations.reviewApproved : translations.reviewUnapproved,
          description: approved ? translations.reviewApprovedDesc : translations.reviewUnapprovedDesc,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error('Failed to approve');
      }
    } catch (error) {
      toast({
        title: translations.reviewApproveError,
        description: translations.reviewApproveErrorDesc,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const filteredReviews = reviews.filter((review) => {
    const matchesSearchTerm = review.carName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                              review.Name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDateRange = (!startDate || new Date(review.createdAt) >= new Date(startDate)) &&
                             (!endDate || new Date(review.createdAt) <= new Date(endDate));
    const matchesCar = !selectedCar || review.carName === selectedCar;
    const matchesRating = ratingFilter === null || review.rating === ratingFilter;
    const matchesOwnerCommented = !ownerCommented || (review.ownerComment && review.ownerComment.trim() !== '');
    const matchesApproval = approvedFilter === 'all' || 
                            (approvedFilter === 'approved' && review.approved) ||
                            (approvedFilter === 'not-approved' && !review.approved);

    return matchesSearchTerm && matchesDateRange && matchesCar && matchesRating && matchesOwnerCommented && matchesApproval;
  });

  const displayReviews = filteredReviews.slice(pagesVisited, pagesVisited + reviewsPerPage);
  const totalPages = Math.ceil(filteredReviews.length / reviewsPerPage);

  return (
    <Box p={4}>
    <Flex justifyContent="space-between" alignItems="center" mb={4}>
      <Flex>
        <InputGroup
          style={{
            width: '100%', // Ensure it takes the full width of its container
            maxWidth: '600px', // Limit the maximum width for better responsiveness
            margin: '0 auto', // Center align if needed
          }}
        >
          <Input
            placeholder={translations.search}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mr={2}
          />
          <InputRightElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
        </InputGroup>
        <Button
          onClick={toggleFilters}
          ml={2}
          style={{
            backgroundColor: '#ffb318', // Dark background color
            color: '#ffffff', // White text color
            padding: '12px 24px',
            fontSize: '16px',
            border: 'none',
            borderRadius: '4px',
            minWidth: '150px', // Ensure buttons have the same minimum width
            cursor: 'pointer'
          }}
        >
  {translations.filter}
</Button>
<Button
  onClick={clearFilters}
  ml={2}
  style={{
    backgroundColor: '#ffb318', // Dark background color
    color: '#ffffff', // White text color
    padding: '12px 24px',
    fontSize: '16px',
    border: 'none',
    borderRadius: '4px',
    minWidth: '150px', // Ensure buttons have the same minimum width
    cursor: 'pointer'
  }}
>
  {translations.clearFilters}
</Button>

        </Flex>
        <Flex alignItems="center">
          {renderAverageRating(reviews)}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <UploadComponent /> {/* Add the UploadComponent here */}
      </Flex>
      {showFilters && (
        <Flex mb={4}>
          <FormControl ml={2}>
            <FormLabel>{translations.startDate}</FormLabel>
            <Input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              mr={2}
            />
          </FormControl>
          <FormControl ml={2}>
            <FormLabel>{translations.endDate}</FormLabel>
            <Input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              mr={2}
            />
          </FormControl>
          <FormControl ml={2}>
            <FormLabel>{translations.carName}</FormLabel>
            <Select value={selectedCar} onChange={handleCarChange}>
              <option value="">{translations.any}</option>
              {Array.from(new Set(reviews.map((review) => review.carName))).map((carName) => (
                <option key={carName} value={carName}>
                  {carName}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl ml={2}>
            <FormLabel>{translations.rating}</FormLabel>
            <Select value={ratingFilter ?? ''} onChange={handleRatingChange}>
              <option value="">{translations.any}</option>
              {[1, 2, 3, 4, 5].map((rating) => (
                <option key={rating} value={rating}>
                  {rating}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl ml={2}>
  <FormLabel>{translations.ownerCommented}</FormLabel>
  <Select value={ownerCommented ? 'yes' : 'no'} onChange={(e) => handleOwnerCommentChange(e.target.value)}>
    <option value="all">All</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </Select>
</FormControl>
<FormControl ml={2}>
<FormLabel>{translations.reviewApproved}</FormLabel>
            <Select value={approvedFilter} onChange={handleApprovedFilterChange}>
              <option value="all">All</option>
              <option value="approved">{translations.reviewApproved}</option>
              <option value="not-approved">{translations.reviewUnapproved}</option>
            </Select>
          </FormControl>
        </Flex>
      )}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{translations.name}</Th>
            <Th>{translations.carName}</Th>
            <Th>{translations.review}</Th>
            <Th>{translations.rating}</Th>
            <Th>{translations.date}</Th>
            <Th>{translations.actions}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {displayReviews.map((review) => (
            <Tr key={review._id}>
              <Td>{review.Name}</Td>
              <Td>{review.carName}</Td>
              <Td>{review.review}</Td>
              <Td>
                <HStack spacing={1}>
                  {[...Array(5)].map((_, index) => (
                    <Icon
                      as={index < review.rating ? FaStar : FaRegStar}
                      key={index}
                      color="yellow.400"
                      w={4}
                      h={4}
                    />
                  ))}
                </HStack>
              </Td>
              <Td>{new Date(review.createdAt).toLocaleDateString()}</Td>
              <Td>
                <ButtonGroup spacing={2}>
                  <IconButton
                    aria-label="View"
                    icon={<ViewIcon />}
                    onClick={() => handleView(review)}
                  
                  />

                {isAdmin && (<IconButton
  aria-label="Delete"
  icon={<DeleteIcon />}
  style={{
    backgroundColor: '#f56565', // Red background
    color: 'white', // White text
    border: 'none', // No border
  }}
  _hover={{
    backgroundColor: '#c53030', // Darker red on hover
  }}
  onClick={() => {
    setReviewToDelete(review._id);
    onDeleteOpen();
  }}
/>)}

{isAdmin && (
  
  <IconButton
    aria-label="Approve"
    icon={<FaCheck />}
    style={{
      backgroundColor: review.approved ? '#38a169' : '#276749', // Green background based on approval
      color: 'white', // White text
      border: 'none', // No border
    }}
    _hover={{
      backgroundColor: review.approved ? '#2f855a' : '#38a169', // Darker green on hover
    }}
    onClick={() => handleApprove(review._id, !review.approved)}
  />
)}

                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent="space-between" alignItems="center" mt={4} margin={50}>
        <IconButton ml={15}
          aria-label="Previous Page"
          icon={<ChevronLeftIcon />}
          onClick={() => handlePageChange(currentPage - 1)}
          isDisabled={currentPage === 0}
        />
        <Text>
          {currentPage + 1} {translations.of} {totalPages}
        </Text>
        <IconButton mr={15}
          aria-label="Next Page"
          icon={<ChevronRightIcon />}
          onClick={() => handlePageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages - 1}
        />
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{translations.reviewDetails}</DrawerHeader>
            <DrawerBody>
              {selectedReview && (
                <Box>
                  <Text fontWeight="bold">{translations.name}: {selectedReview.Name}</Text>
                  <Text fontWeight="bold">{translations.carName}: {selectedReview.carName}</Text>
                  <Text fontWeight="bold">{translations.review}: {selectedReview.review}</Text>
                  <Text fontWeight="bold">{translations.rating}: {selectedReview.rating}</Text>
                  <Text fontWeight="bold">{translations.date}: {new Date(selectedReview.createdAt).toLocaleDateString()}</Text>
                  <FormControl mt={4}>
                    <FormLabel>{translations.ownerComment}</FormLabel>
                    <Textarea
                      placeholder={translations.enterComment}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </FormControl>
                  {isAdmin && (
                    <FormControl mt={4}>
                      <FormLabel>{translations.reviewApproved}</FormLabel>
                      <Checkbox
                        isChecked={selectedReview.approved}
                        onChange={(e) => handleApprove(selectedReview._id, e.target.checked)}
                      >
                        {translations.reviewApproved}
                      </Checkbox>
                    </FormControl>
                  )}
                  <Button colorScheme="blue" mt={4} onClick={handleSaveComment}>
                    {translations.saveComment}
                  </Button>
                </Box>
              )}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <AlertDialog
        isOpen={isDeleteOpen}
        leastDestructiveRef={cancelRef}
        onClose={onDeleteClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>{translations.deleteReview}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              {translations.deleteReviewConfirm}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onDeleteClose}>
                {translations.cancel}
              </Button>
              <Button 
  onClick={handleDelete} 
  ml={3} 
  sx={{ 
    backgroundColor: "red.500", // force background color
    color: "white", // force text color
    _hover: {
      bg: "red.600", // hover state
    }
  }}
>
  {translations.delete}
</Button>

            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default Dashboard;
