// src/firebaseConfig.ts

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";  // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCkaNMxYwaNS2j4e8GeHMaZpVSLqs9on-A",
  authDomain: "af-automobiles.firebaseapp.com",
  projectId: "af-automobiles",
  storageBucket: "af-automobiles.appspot.com",
  messagingSenderId: "252708898634",
  appId: "1:252708898634:web:18fb4b93ee30642896a3b3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage and Firestore
const storage = getStorage(app);
const firestore = getFirestore(app);  // Initialize Firestore

export { storage, firestore, app };
