// theme.ts

import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import backgroundImage from './car_pattern.png'; // Adjust the path as needed


// Add your color mode config
const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

// Extend the theme with custom button colors
const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      body: {
        position: 'relative',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'repeat-y', // Repeat both horizontally and vertically
          backgroundSize:'100%',
          opacity: 0.1, // Set the desired opacity here
          zIndex: -1, // Make sure it stays behind other content
        },
      },
    }),
  },
  components: {
    Button: {
      baseStyle: (props: any) => ({
        bg: mode('#ffb218', '#ffb218')(props),
        color: mode('white', 'gray.800')(props),
      }),
      variants: {
        solid: (props: any) => ({
          bg: mode('#ffb218', '#ffb218')(props),
          _hover: {
            bg: mode('#e09e17', '#e09e17')(props),
          },
        }),
      },
    },
  },
});

export default theme;
