import React, { useState, useEffect } from 'react';
import { storage } from '../firebaseConfig';
import { ref, uploadBytes, listAll, deleteObject, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import { Box, Button, Input, useToast, Progress, Image, Flex, Checkbox } from '@chakra-ui/react';

const UploadComponent: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [existingImages, setExistingImages] = useState<{ url: string, approved: boolean }[]>([]);
  const toast = useToast();

  useEffect(() => {
    fetchImages();
  }, []);

  // Fetch images from Firebase Storage and their approval status from backend
  const fetchImages = async () => {
    try {
      const imageRefs = ref(storage, 'uploads');
      const imageList = await listAll(imageRefs);

      // Get download URLs and default approval status
      const imageUrls = await Promise.all(
        imageList.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return { url, approved: false }; // Default approval status before updating
        })
      );

      // Fetch approval status from backend
      const { data: approvedImages }: { data: { url: string, approved: boolean }[] } = await axios.get('https://backend.afmobile.de/api/images/approved');
      
      // Update the approval status in the local state
      const updatedImages = imageUrls.map((image) => {
        const approvedImage = approvedImages.find(approved => approved.url === image.url);
        return approvedImage ? { ...image, approved: approvedImage.approved } : image;
      });

      setExistingImages(updatedImages);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      setFiles(selectedFiles);
    }
  };

  const handleCheckboxChange = async (url: string, currentStatus: boolean) => {
    try {
      await axios.put('https://backend.afmobile.de/api/images/approve', { url, approved: !currentStatus });
      setExistingImages(prevImages =>
        prevImages.map(image =>
          image.url === url ? { ...image, approved: !currentStatus } : image
        )
      );
    } catch (error) {
      console.error('Error updating approval status:', error);
      toast({
        title: 'Update failed',
        description: 'There was an error updating the approval status.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpload = async () => {
    if (files.length > 3) {
      toast({
        title: 'Invalid number of files',
        description: 'You can only select up to 3 files in total.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setUploading(true);

    try {
      const uploadPromises = files.map(async (file) => {
        const fileRef = ref(storage, `uploads/${file.name}`);
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);

        // Save URL to backend database
        await axios.post('https://backend.afmobile.de/api/images/upload', { url: downloadURL });

        return { url: downloadURL, approved: false }; // Default approval status
      });

      const newImages = await Promise.all(uploadPromises);
      setExistingImages(prevImages => [...prevImages, ...newImages]);

      setFiles([]);
      toast({
        title: 'Upload successful',
        description: 'Your files have been uploaded.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Upload failed:', error);
      toast({
        title: 'Upload failed',
        description: 'There was an error uploading your files.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (url: string) => {
    try {
      // Delete from Firebase Storage
      // const fileRef = ref(storage, `uploads/${url}`);
      // await deleteObject(fileRef);

      // If Firebase deletion succeeds, then delete from backend
      await axios.delete('https://backend.afmobile.de/api/images/delete', { data: { url } });

      // Update state to remove the deleted image
      setExistingImages(existingImages.filter(image => image.url !== url));

      toast({
        title: 'Delete successful',
        description: 'The image has been deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Delete failed:', error);
      toast({
        title: 'Delete failed',
        description: 'There was an error deleting the image.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Input
        type="file"
        onChange={handleFileChange}
        mb={4}
        multiple  // Allow multiple files
      />
      <Button
        onClick={handleUpload}
        isLoading={uploading}
        isDisabled={files.length === 0}
        style={{
          backgroundColor: '#ffb318',
          color: '#ffffff',
        }}
        _hover={{
          backgroundColor: '#ff9e18',
        }}
      >
        Upload
      </Button>
      {uploading && <Progress size="xs" isIndeterminate />}
      <Flex mt={4} wrap="wrap" gap={4}>
        {existingImages.map((image) => (
          <Box key={image.url} position="relative" border={image.approved ? '2px solid green' : '2px solid gray'} p={2} borderRadius="md">
            <Image src={image.url} boxSize="150px" objectFit="cover" />
            <Checkbox
              position="absolute"
              top={2}
              left={2}
              isChecked={image.approved}
              onChange={() => handleCheckboxChange(image.url, image.approved)}
            >
              Approved
            </Checkbox>
            <Button
              position="absolute"
              top={2}
              right={2}
              size="xs"
              colorScheme="red"
              onClick={() => handleDelete(image.url)}
            >
              Delete
            </Button>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default UploadComponent;
