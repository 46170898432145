import React from 'react';
import { Box, Image, useBreakpointValue, Text } from '@chakra-ui/react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

interface ImageCarouselProps {
  images: string[];
}

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <FaArrowRight
      className={className}
      style={{ ...style, display: 'block', color: 'white', zIndex: 1, position: 'absolute', right: '10px', top: '50%' }}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <FaArrowLeft
      className={className}
      style={{ ...style, display: 'block', color: 'white', zIndex: 1, position: 'absolute', left: '10px', top: '50%' }}
      onClick={onClick}
    />
  );
};

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const isSingleImage = images.length === 1;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...(images.length > 1 && {
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
    }),
  };

  const objectFit = useBreakpointValue({ base: 'contain', md: 'cover' }) as string;

  return (
<Box width="100%" mb={4} position="relative" margin="0" padding="0">
  {images.length > 0 && (
    isSingleImage ? (
      <Image src={images[0]} alt={`Image 1`} width="100%" objectFit={objectFit as any} maxHeight='400px' />
    ) : (
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box key={index} overflow="hidden" position="relative" maxH={{ md: '400px' }}>
            <Image src={image} alt={`Image ${index + 1}`} width="100%" objectFit={objectFit as any} />
          </Box>
        ))}
      </Slider>
    )
  )}
</Box>
  );
};

export default ImageCarousel;
