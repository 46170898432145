import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

type LanguageContextType = {
  language: keyof typeof translations;
  toggleLanguage: () => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

const translations = {
  en: {
    home: "Home",
    dashboard: "Dashboard",
    adminLogin: "Admin Login",
    enterPassword: "Enter admin password",
    incorrectPassword: "Incorrect password",
    login: "Login",
    cancel: "Cancel",
    logout: "Logout",
    firstLetterOfName: "First Letter of Name",
    lastName: "Last Name",
    name: "Name",
    email: "Email",
    carName: "Car Mark",
    review: "Review",
    reviews: "Reviews",
    rating: "Rating",
    submitReview: "Submit Review",
    firstLetterOfNameError: "First letter of name is required and should be one letter",
    nameError: "Name is required",
    carNameError: "Car mark is required",
    reviewError: "Review is required",
    ratingError: "Rating is required",
    emailError: "Please enter a valid email address",
    successMessage: "Review submitted successfully!",
    errorMessage: "There was an error submitting your review.",
    averageRating: "Average Rating",
    filter: "Filter", // Added
    clearFilters: "Clear Filters",
    startDate: "Start Date",
    endDate: "End Date",
    selectCar: "Select Car",
    ownerCommented: "Owner Commented",
    actions: "Actions",
    view: "View",
    delete: "Delete",
    previous: "Previous",
    next: "Next",
    reviewDetails: "Review Details",
    comment: "Comment",
    save: "Save",
    reviewDeleted: "Review Deleted",
    reviewDeletedDesc: "The review has been deleted successfully.",
    reviewDeleteError: "Error Deleting Review",
    reviewDeleteErrorDesc: "There was an error deleting the review.",
    deleteReview: "Delete Review",
    deleteReviewConfirm: "Are you sure you want to delete this review?",
    commentUpdated: "Comment Updated",
    commentUpdatedDesc: "The comment has been updated successfully.",
    commentUpdateError: "Error Updating Comment",
    commentUpdateErrorDesc: "There was an error updating the comment.",
    search: "Search", // Added
    filters: "Filters", // Added
    any: "Any", // Added
    onlyOwnerCommented: "Only Owner Commented", // Added
    date: "Date", // Added
    page: "Page", // Added
    of: "of", // Added
    viewReview: "View Review", // Added
    ownerComment: "Owner Comment", // Added
    enterComment: "Enter Comment", // Added
    saveComment: "Save Comment", // Added
    swipeToViewMore: "Swipe left and right to view more", // Added
    reviewApproved: "Review Approved",
    reviewUnapproved: "Review Unapproved",
    reviewApprovedDesc: "The review has been approved successfully.",
    reviewUnapprovedDesc: "The review has been unapproved successfully.",
    reviewApproveError: "Error Approving Review",
    reviewApproveErrorDesc: "There was an error approving the review.",
    submitReviewButton: "Submit Your Own Review",
    submitReviewHeader: "Submit Review"
  },
  de: {
    home: "Startseite",
    dashboard: "Armaturenbrett",
    adminLogin: "Admin Login",
    enterPassword: "Admin-Passwort eingeben",
    incorrectPassword: "Falsches Passwort",
    login: "Anmelden",
    cancel: "Stornieren",
    logout: "Abmelden",
    firstLetterOfName: "Erster Buchstabe des Namens",
    lastName: "Nachname",
    email: "Email",
    name: "Name",
    reviews: "Bewertungen",
    carName: "Auto Marke",
    review: "Bewertung",
    rating: "Bewertung",
    submitReview: "Bewertung abgeben",
    firstLetterOfNameError: "Der erste Buchstabe des Namens ist erforderlich und sollte ein Buchstabe sein",
    nameError: "Nachname ist erforderlich",
    carNameError: "Auto Marke ist erforderlich",
    reviewError: "Bewertung ist erforderlich",
    ratingError: "Bewertung ist erforderlich",
    emailError: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    successMessage: "Bewertung erfolgreich eingereicht!",
    errorMessage: "Es gab einen Fehler beim Einreichen Ihrer Bewertung.",
    averageRating: "Durchschnittliche Bewertung",
    filter: "Filter", // Added
    clearFilters: "Filter löschen",
    startDate: "Anfangsdatum",
    endDate: "Enddatum",
    selectCar: "Auto auswählen",
    ownerCommented: "Kommentar des Eigentümers",
    actions: "Aktionen",
    view: "Ansehen",
    delete: "Löschen",
    previous: "Vorherige",
    next: "Nächste",
    reviewDetails: "Bewertungsdetails",
    comment: "Kommentar",
    save: "Speichern",
    reviewDeleted: "Bewertung gelöscht",
    reviewDeletedDesc: "Die Bewertung wurde erfolgreich gelöscht.",
    reviewDeleteError: "Fehler beim Löschen der Bewertung",
    reviewDeleteErrorDesc: "Beim Löschen der Bewertung ist ein Fehler aufgetreten.",
    deleteReview: "Bewertung löschen",
    deleteReviewConfirm: "Möchten Sie diese Bewertung wirklich löschen?",
    commentUpdated: "Kommentar aktualisiert",
    commentUpdatedDesc: "Der Kommentar wurde erfolgreich aktualisiert.",
    commentUpdateError: "Fehler beim Aktualisieren des Kommentars",
    commentUpdateErrorDesc: "Beim Aktualisieren des Kommentars ist ein Fehler aufgetreten.",
    search: "Suche", // Added
    filters: "Filter", // Added
    any: "Irgendein", // Added
    onlyOwnerCommented: "Nur Eigentümer kommentiert", // Added
    date: "Datum", // Added
    page: "Seite", // Added
    of: "von", // Added
    viewReview: "Bewertung anzeigen", // Added
    ownerComment: "Eigentümer Kommentar", // Added
    enterComment: "Kommentar eingeben", // Added
    saveComment: "Kommentar speichern", // Added
    swipeToViewMore: "Wischen Sie nach links und rechts, um mehr zu sehen", // Added
    reviewApproved: "Bewertung genehmigt",
    reviewUnapproved: "Bewertung nicht genehmigt",
    reviewApprovedDesc: "Die Bewertung wurde erfolgreich genehmigt.",
    reviewUnapprovedDesc: "Die Bewertung wurde erfolgreich nicht genehmigt.",
    reviewApproveError: "Fehler beim Genehmigen der Bewertung",
    reviewApproveErrorDesc: "Beim Genehmigen der Bewertung ist ein Fehler aufgetreten.",
    submitReviewButton: "Eigene Bewertung abgeben",
    submitReviewHeader: "Bewertung abgeben"
  }
};

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useState<keyof typeof translations>('de'); // Set default language to German

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') as keyof typeof translations;
    if (storedLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  const toggleLanguage = () => {
    const newLanguage = language === 'de' ? 'en' : 'de';
    setLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export const useTranslations = () => {
  const { language } = useLanguage();
  return translations[language];
};