import * as React from "react";
import { ChakraProvider, Box, IconButton, Link, VStack } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import theme from "./theme";
import Dashboard from './pages/Dashboard';
import Home from "./pages/Home";
import ReviewCards from "./pages/ReviewCards"; // Import the ReviewCards component
import { PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import { FaWhatsapp } from 'react-icons/fa'; // Import the WhatsApp icon from react-icons/fa
import Header from "./components/Header";
import { LanguageProvider } from './LanguageContext'; // Import LanguageProvider

export const App = () => {
  const [isAdmin, setIsAdmin] = React.useState(false);

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <LanguageProvider>

            <Header setIsAdmin={setIsAdmin} />
            <Routes>
              <Route path="/" element={<ReviewCards />} />
              <Route path="/admin/aymanfarhat/j250nwf1c4z6W73M" element={<Dashboard isAdmin={isAdmin} />} />
              <Route path="/review" element={<Home />} /> {/* Add the route for ReviewCards */}
            </Routes>
            <VStack spacing={4} position="fixed" bottom={4} right={4}>
              <Link href="tel:+4915730414301" isExternal>
                <IconButton
                  icon={<PhoneIcon />}
                  aria-label="Call"
                  colorScheme="teal"
                  size="lg"
                  isRound
                />
              </Link>
              <Link href="https://wa.me/4915730414301" isExternal>
                <IconButton
                  icon={<FaWhatsapp />}
                  aria-label="WhatsApp"
                  size="lg"
                  isRound
                  style={{
                    backgroundColor: '#25D366', // WhatsApp green color
                    color: 'white', // White icon color
                  }}
                />
              </Link>
              <Link href="mailto:af.auto@hotmail.com" isExternal>
                <IconButton
                  icon={<EmailIcon />}
                  aria-label="Email"
                  colorScheme="teal"
                  size="lg"
                  isRound
                />
              </Link>
            </VStack>
        </LanguageProvider>
      </Router>
    </ChakraProvider>
  );
};
